import "./styles.css";
import oneSoulmate from "./images/soulmate/1.png";
import twoSoulmate from "./images/soulmate/2.png";
import threeSoulmate from "./images/soulmate/3.png";
import fourSoulmate from "./images/soulmate/4.png";
import fiveSoulmate from "./images/soulmate/5.png";
import sixSoulmate from "./images/soulmate/6.png";
import videoSoulmate from "./images/soulmate/soulmate.mp4";

export default function Soulmate() {
  return (
    <div className="soulmate-wrapper">
      <section className="section1">
      <h2 className="mobile-reveal">Soulmate</h2>
        <div className="equal-col col1">
          <div className="col-5 equal-col">
            <div className="col-6">
              <h2 className="mobile-erase">Soulmate</h2>
              <p>
                {" "}
                Un suflet pereche este asemenea celui mai bun prieten. E unica
                ființă care te cunoaște mai bine decât oricine. Este cel care te
                face o persoană mai bună.{" "}
              </p>
              <p>
                {" "}
                De fapt, nu te face să devii mai bun, o faci natural... pentru
                că te inspiră.{" "}
              </p>
              <div class="cta-colectie soulmate-cta mobile-erase"> 
              <a href={"https://www.teilor.ro/soulmate.html"}>
              <div className="button1"> Descoperă Colecția </div>
              </a>
               </div>
            </div>
            <div className="col-7">
              <div className="col-1">
                <a href={"https://www.teilor.ro/soulmate.html"}>
                  <img className="zoom" src={oneSoulmate} />
                </a>
              </div>
              <div className="col-1">
                <img src={twoSoulmate} />
              </div>
            </div>
          </div>
          <div class="col-1 mobile-reveal"><div class="cta-colectie soulmate-cta"> 
              <a href={"https://www.teilor.ro/soulmate.html"}>
              <div className="button1"> Descoperă Colecția </div>
              </a>
               </div></div>
          <div className="col-3">
            <div className="col-1">
              <div className="col-1">
                <video
                  className="videoSoulmate"
                  playsInline
                  autoPlay
                  loop
                  muted
                  controls
                >
                  <source src={videoSoulmate} type="video/mp4" />
                </video>
              </div>
              <div className="col-2">
                <a href={"https://www.teilor.ro/soulmate.html"}>
                  <img className="zoom" src={threeSoulmate} />
                </a>
              </div>
              <div className="col-2">
                <img src={fourSoulmate} />
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="col-1">
              <img src={fiveSoulmate} />
            </div>
            <div className="col-1">
              <a href={"https://www.teilor.ro/soulmate.html"}>
                <img className="zoom" src={sixSoulmate} />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
