import "./styles.css";
import insta1 from "./images/footer/insta1.png";
import insta2 from "./images/footer/insta2.png";
import insta3 from "./images/footer/insta3.png";
import insta4 from "./images/footer/insta4.png";
import insta5 from "./images/footer/insta5.png";
import insta6 from "./images/footer/insta6.png";
import instaIcon from "./images/footer/instagram1.svg";

export default function Footer() {
  return (
    <div>
      <section className="section1">
        <div className="footer1">
          <div className="footer-top">
            <div className="instagram-icon">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                {" "}
                <img src={instaIcon} alt="" />{" "}
              </a>
            </div>
            <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
              <div className="button1"> follow us </div>
            </a>
            <p>
              Bijuteriile fac întotdeauna diferența. <br /> Inspiră-te cu
              TEILOR.
            </p>
          </div>
          <div className="footer-grid">
            <div className="footer-grid-item">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                <img className="zoom" src={insta1} alt="" />
              </a>
            </div>
            <div className="footer-grid-item">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                <img className="zoom" src={insta2} alt="" />
              </a>
            </div>
            <div className="footer-grid-item">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                <img className="zoom" src={insta3} alt="" />
              </a>
            </div>
            <div className="footer-grid-item">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                <img className="zoom" src={insta4} alt="" />
              </a>
            </div>
            <div className="footer-grid-item">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                <img className="zoom" src={insta5} alt="" />
              </a>
            </div>
            <div className="footer-grid-item">
              <a href={"https://www.instagram.com/teilor_finejewellery/?hl=ro"}>
                <img className="zoom" src={insta6} alt="" />
              </a>
            </div>
          </div>
          <div className="footer-bottom">
            <a href={"https://www.teilor.ro"}>
              <div className="button1">
                {" "}
                 teilor.ro{" "}
              </div>
            </a>
            <div className="copy"> All rights © 2021 Teilor.ro</div>
          </div>
        </div>
      </section>
    </div>
  );
}
