import "./styles.css";

import oneAntonia from "./images/antonia/1.png";
import twoAntonia from "./images/antonia/2.png";
import threeAntonia from "./images/antonia/3.png";
import fourAntonia from "./images/antonia/4.png";
import fiveAntonia from "./images/antonia/5.png";
import sixAntonia from "./images/antonia/6.png";
import videoAntonia from "./images/antonia/antoniam.mp4";

export default function Antonia() {
  return (
    <div className="Antonia-wrapper">
      <section className="section1">
      <h2 class="mobile-reveal">
                Pure diamonds
              </h2>
        <div className="equal-col col1">
          <div className="col-5 equal-col">
            <div className="col-6">
              <h2 class="mobile-erase">
                Pure diamonds
              </h2>
              <p>
                {" "}
                O reflexie a luxului și a unui stil aparte, o poveste a două
                femei puternice, pe care le definesc curajul, atitudinea și
                încrederea. Viața nu vine cu un manual de utilizare, ci cu o
                mamă.{" "}
              </p>
              <p> Antonia & Denise Iacobescu: dragoste necondiționată. </p>
              <div class="cta-colectie mobile-erase"> <a href={"https://www.teilor.ro/pure-diamonds.html"}>
                <div className="button1"> Descoperă Colecția </div> 
                </a>
                </div>
            </div>
            <div className="col-7">
              <div className="col-1">
                <a href={"https://www.teilor.ro/pure-diamonds.html"}>
                  <img className="zoom" src={oneAntonia} />
                </a>
              </div>
              <div className="col-1">
                <img src={twoAntonia} />
              </div>
            </div>
          </div>
          <div class="col-1 mobile-reveal"><div class="cta-colectie"> <a href="https://www.teilor.ro/pure-diamonds.html"><div class="button1"> Descoperă Colecția </div></a></div></div>
          <div className="col-3">
            <div className="col-1">
              <div className="col-2">
                <img src={threeAntonia} />
              </div>
              <div className="col-2">
                <a href={"https://www.teilor.ro/pure-diamonds.html"}>
                  <img className="zoom" src={fourAntonia} />
                </a>
              </div>
              <div className="col-1">
                <video className="videoAntonia" playsInline autoPlay loop muted controls>
                  <source src={videoAntonia} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>

          <div className="col-4">
            <div className="col-1">
              <img src={fiveAntonia} />
            </div>
            <div className="col-1">
              <a href={"https://www.teilor.ro/pure-diamonds.html"}>
                <img className="zoom" src={sixAntonia} />{" "}
              </a>{" "}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
