import "./styles.css";
import oneExclusive from "./images/exclusive/1.png";
import twoExclusive from "./images/exclusive/2.png";
import threeExclusive from  "./images/exclusive/3.png";
import fourExclusive from "./images/exclusive/4.png";
import fiveExclusive from "./images/exclusive/5.png";
import sixExclusive from "./images/exclusive/6.png";
import videoExclusive from "./images/exclusive/exclusive.mp4";


export default function Exclusive() {
  return (
    <div className="exclusive-wrapper">
      <section className="section1">
      <h2 className="mobile-reveal">Teilor exclusive</h2>
<div className="equal-col col1"> 

<div className="col-5 equal-col">
  <div className="col-6">	
  <h2 className="mobile-erase">Teilor exclusive</h2>	 
      <p> Bijuterii unicat, încrustate în diamante și pietre prețioase. Piese deosebite, pe care nimeni altcineva nu le va deține, ci doar aprecia. </p>	
      <p> Unicitatea și design-ul maiestuos sunt cele mai importante criterii ce definesc colecțiile TEILOR Exclusive.   </p>	
     <div class="cta-colectie mobile-erase"><a href={'https://www.teilor.ro/exclusive.html'}>
        <div className="button1"> Descoperă Colecția </div>
        </a>
         </div>
</div>
<div className="col-7">		 
<div className="col-1"><a href={'https://www.teilor.ro/exclusive.html'}><img className="zoom" src={oneExclusive} /></a></div>	
<div className="col-1"><a href={'https://www.teilor.ro/exclusive.html'}><img className="zoom" src={twoExclusive}/></a></div>	
</div>
</div> 
<div className="col-1 mobile-reveal">
<div class="cta-colectie"><a href={'https://www.teilor.ro/exclusive.html'}>
        <div className="button1"> Descoperă Colecția </div>
        </a>
         </div>
  </div>	
  
<div className="col-3 increase-width">
<div className="col-1">		
<div className="col-2"><img  src={threeExclusive}/></div>	
<div className="col-2"><a href={'https://www.teilor.ro/exclusive.html'}><img className="zoom" src={fourExclusive}/></a></div>	
<div className="col-1">
<video className='videoExclusive' playsInline autoPlay loop muted controls>
    <source src={videoExclusive} type='video/mp4' />
    </video>
  </div>	
</div>
</div> 		  

<div className="col-4">
<div className="col-1">
<img  src={fiveExclusive}/>
    </div>	
<div className="col-1"><a href={'https://www.teilor.ro/exclusive.html'}><img className="zoom" src={sixExclusive}/></a></div>	
</div> 



</div>
</section> 

    </div>
  );
}
