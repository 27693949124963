import "./styles.css";

//import Carousel from "./carousel.js"
export default function BirthstonesLanding() {
  return (
 
<div>
  <section className="section1 margin-reducer">
              <div className="birth-center">	
              <div className="title-birthstones">
                 
                 
                 <h2>Birthstones</h2>
                 <p>
                 Autentice, neconveționale, în culori puternice – fiecare piatră a lunii e unică.<br/> Descoperă o interpretare modernă a lunii tale aniversare, conturată în calități extraordinare.
                 </p>
                
                 </div>

      </div>


</section> 
	





</div>
    
  );
}
