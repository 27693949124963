import React from "react";
import ReactDOM from "react-dom";
import Antonia from "./antonia.js";
import Exclusive from "./exclusive.js";
import Soulmate from "./soulmate.js";
import Lovestory from "./lovestory.js";
import Landing from "./landing.js";
import Footer from "./footer.js";
import BirthstonesLanding from "./birthstoneslanding.js";
import BirthstonesMedia from "./birthstones-media.js";
import Slider from "react-slick";
import "./slick-variable.css";
import "./slick-theme.min.css";
import "./slick.min.css";
import Inele from "./images/categories/before/inele.png";
import Cercei from "./images/categories/before/cercei.png";
import Bratari from "./images/categories/before/bratari.png";
import Coliere from "./images/categories/before/coliere.png";
import Inele2 from "./images/categories/before/inele2.png";
import Verighete from "./images/categories/before/verighete.png";
import january from "./images/birthstones/01.png";
import february from "./images/birthstones/02.png";
import march from "./images/birthstones/03.png";
import april from "./images/birthstones/04.png";
import may from "./images/birthstones/05.png";
import june from "./images/birthstones/06.png";
import july from "./images/birthstones/07.png";
import august from "./images/birthstones/08.png";
import september from "./images/birthstones/09.png";
import october from "./images/birthstones/10.png";
import november from "./images/birthstones/11.png";
import december from "./images/birthstones/12.png";
import newsForbes from "./images/newsarticle.png";
import videoBaneasa from "./images/news/baneasa.mp4";
import videoForbes from "./images/news/forbes.mp4";
import newsAlice from "./images/news/alice.png";
import videoPoster2 from "./images/baneasa.png";
import videoPoster1 from "./images/forbes.png";

const rootElement = document.getElementById("root");
class Categories extends React.Component {
  render() {
    const settings = {
      className: "slider variable-width",
      arrows:false,
      rows: 1,
      infinite: true,
      slidesToShow: 6,
      slidesToScroll: 3,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            centerMode:false,
            arrows:false,
            infinite: true,
            draggable: false,
            swipeToSlide: false,
            touchMove: true,
            rows: 1,
            slidesToShow: 2.2,
            slidesToScroll: 2
          }
         
        }
      ]
    };
    return (
      <section className="section1"
        style={{
          margin: "0 0px",
        }}
      >
       
        <Slider {...settings}>
          <div style={{ width: 260 }}>
          <a href={'https://www.teilor.ro/inele-de-logodna.html'}> <img className="img-categorie zoomsmall" src={Inele} alt="" /><p class="titlu-categorie">
          <span class="underline-category">Inele de logodna</span>
            </p></a>
          </div>
          <div  style={{ width: 260 }}>
          <a href={'https://www.teilor.ro/bijuterii/cercei.html'}><img className="img-categorie zoomsmall" src={Cercei} alt="" /><p class="titlu-categorie">
          <span class="underline-category">  Cercei </span>
            </p></a>
          </div>
          <div style={{ width: 260 }}>
          <a href={'https://www.teilor.ro/bijuterii/bratari.html'}><img className="img-categorie zoomsmall" src={Bratari} alt="" /><p class="titlu-categorie">
            <span class="underline-category">Bratari</span>
            </p></a>
          </div>
          <div style={{ width: 260 }}>
          <a href={'https://www.teilor.ro/bijuterii/coliere.html'}><img className="img-categorie zoomsmall" src={Coliere} alt="" />
          <p class="titlu-categorie">
          <span class="underline-category"> Coliere</span>
            </p>
          </a>
          </div>
          <div style={{ width: 260 }}>
          <a href={'https://www.teilor.ro/bijuterii/inele.html'}><img className="img-categorie zoomsmall" src={Inele2} alt="" />
          <p class="titlu-categorie">
          <span class="underline-category"> Inele</span>
            </p> 
          </a>
          </div>
          <div style={{ width: 260 }}>
          <a href={'https://www.teilor.ro/verighete.html'}><img className="img-categorie zoomsmall" src={Verighete} alt="" />
          <p class="titlu-categorie">
          <span class="underline-category">Verighete</span>
            </p>
          </a>
          </div>
        </Slider>
      </section>
    );
  }
}

class Birth extends React.Component {
  render() {
    const settings = {
      touchThreshold:200,
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "50px",
        slidesToShow: 5,
        speed: 800,
        focusOnSelect: true,
        dots:false,
        arrows:false,
        slidesToScroll: 1,
        initialSlide: 4,
      
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            touchThreshold:10,
            focusOnSelect: true,
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "50px",
            slidesToShow: 3,
            speed: 500,
            dots:false,
            arrows:false,
            slidesToScroll: 1,
            initialSlide: 3,
          }
        }
      ]
    };
    return (
      <section className="section1 birth-mobile-spread"
        style={{
          margin: "0 0px",
        }}
      >
       
        <Slider {...settings}>
          <div style={{ width: 260 }}>
<h1>Ianuarie</h1>
          <img src={january} alt="" />
<h3>granat</h3>
<p>piatra prosperității și a abundenței; simbolul prieteniei</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Februarie</h1>
          <img src={february} alt="" />
          <h3>ametist</h3>
<p> piatră a păcii, cu însușiri protectoare și purificatoare</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Martie</h1>
          <img src={march} alt="" />
          <h3>acvamarin</h3>
<p>întruchipează armonia și liniștea; transmite loialitate și speranță</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Aprilie</h1>
          <img src={april} alt="" />
          <h3>diamant</h3>
          <p>emblemă a purității și perfecțiunii; piatra angajamentului</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Mai</h1>
          <img src={may} alt="" />
          <h3>smarald</h3>
<p>simbolizează inteligența și iubirea, fiind piatra prețioasă a regalității</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Iunie</h1>
          <img src={june} alt="" />
          <h3>topaz</h3>
<p>Piatra iubirii și a loialității; simbolizează romantism și prietenie</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Iulie</h1>
          <img src={july} alt="" />
          <h3>rubin</h3>
<p>sinonim cu energia și senzualitatea, simbolizează puritate și pasiune</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>August</h1>
          <img src={august} alt="" />
          <h3>peridot</h3>
          <p>prin proprietățile sale energetice, inspiră elocvență și creativitate</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Septembrie</h1>
          <img src={september} alt="" />
          <h3>safir</h3>
          <p>piatra prețioasă a purității, înțelepciunii și a norocului</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Octombrie</h1>
          <img src={october} alt="" />
          <h3>opal</h3>
          <p>asociat cu dragostea și pasiunea, aduce loialitate și fidelitate</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Noiembrie</h1>
          <img src={november} alt="" />
          <h3>citrin</h3>
          <p>activează creativitatea și încurajează atitudinea pozitivă</p>
          </div>
          <div style={{ width: 260 }}>
          <h1>Decembrie</h1>
          <img src={december} alt="" />
          <h3>tanzanit</h3>
          <p>unic prin nuanțele sale albastre-violet; stimulează intuiția</p>
          </div>
        </Slider>
        <div class="cta-colectie mobile-cta-birth"> <a href={"https://www.teilor.ro/index.php?Fl3svu4FwF=1&page=search&action=products&query=smarald"}> <div className="button1"> Descoperă Colecția </div></a> </div>
      </section>
    );
  }
}

class News extends React.Component {
  render() {
    const settings = {
      className: "slider variable-width",
      arrows:false,
      rows: 1,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            variableWidth: true,
            draggable: false,
            swipeToSlide: false,
            touchMove: true,
            rows: 1,
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <section className="section1 news-slider"
        style={{
          margin: "0 0px",
        }}
      >
    
 
              	
              <div className="title-news">
                 
                 
                 <h2>Happenings</h2>
                 <p className="news-center">
                 Brilliant is our world. And our word. Știm cine suntem și modul în care evoluăm.<br/> Iar inspirația ne definește; e adânc impregnată în misiunea noastră.
                 </p>
                
                 </div>


        <Slider {...settings}>
          <div style={{ width: 420 }}>
          <video
              className="videoAntonia"
              playsInline
              loop
              controls
              preload="none"
              poster={videoPoster1}
            >
              <source src={videoBaneasa} type="video/mp4" />
            </video>
<p className="article-titles">TEILOR New Concept Store @ Băneasa</p>
          </div>
          <div  style={{ width: 230 }}>
          <a href={'https://www.forbes.ro/200-de-bijuterii-unicate-premiera-magazinele-teilor-exclusive-209531'}><img src={newsForbes} alt="" /><p className="article-titles">200 bijuterii unicat @ TEILOR Exclusive</p></a>
          </div>
          <div style={{ width: 420 }}>
          <video
              className="videoAntonia"
              playsInline
              loop
              controls
              preload="none"
              poster={videoPoster2}
            >
              <source src={videoForbes} type="video/mp4" />
            </video>
            <p className="article-titles">TEILOR X Gala Forbes 500</p>
          </div>
          <div style={{ width: 230 }}>
          <a href={'https://www.instagram.com/p/CLmUz9gpVtW/'}><img src={newsAlice} alt="" /><p className="article-titles">TEILOR X L'Officiel Monaco</p></a>
          </div>
          
        </Slider>
      </section>
    );
  }
}

ReactDOM.render(
  <div>
<Landing />
<Categories />
<BirthstonesLanding />
<Birth/>
<BirthstonesMedia />
<Soulmate />
<Antonia />
<Lovestory />
<Exclusive />
<News />
<Footer />
    </div>,
  rootElement
);
