import "./styles.css";

import background from "./images/separator.png";
import backgroundEmerald from "./images/birthstones/fundal.jpg"
//import separator1 from "./images/birthstones/separator1-img1-1.png";
import separator2 from "./images/birthstones/separator1-mobil1.png";
import separator3 from "./images/birthstones/separator1-img3.png";
import separator4 from "./images/birthstones/separator1-img4.png";
import separator5 from "./images/birthstones/separator1-img5.png";
import diamondsVideo from "./images/diamonds.mp4";
import emeraldRed from "./images/birthstones/emeraldred.mp4";
import griEmerald from "./images/birthstones/gri-emerald.jpg";
import redEmerald from "./images/birthstones/rosu-emerald.jpg";
import turcoazEmerald from "./images/birthstones/turcoaz-emerald.jpg";
import emeraldMobile from "./images/birthstones/emerald-mobile.jpg"
//import Carousel from "./carousel.js"
export default function BirthstonesMedia() {
  return (
    
    <div>
      
      <section
        className="section1 sep1"
        style={{ backgroundImage: `url(${backgroundEmerald})` }}
      >
        <div>
          <div className="separator1">
            
            <div className="separator1-item-1">
              <video
                className="videoDropthemic"
                playsInline
                autoPlay
                loop
                muted
                controls
              >
                <source src={emeraldRed} type="video/mp4" />
              </video>
            </div>
            <div className="separator1-item-1 mobil birth-trilogy">
              <a href={"https://www.teilor.ro/index.php?Fl3svu4FwF=1&page=search&action=products&query=smarald"}>
                <img src={emeraldMobile} alt="" />
              </a>
            </div>
          </div>
          <div className="separator1 desc">
            <div className="separator1-item-2">
              <a href={"https://www.teilor.ro/index.php?Fl3svu4FwF=1&page=search&action=products&query=smarald"}>
                <img className="zoom" src={griEmerald} alt="" />
              </a>
            </div>
            <div className="separator1-item-2">
              <a href={"https://www.teilor.ro/index.php?Fl3svu4FwF=1&page=search&action=products&query=smarald"}>
                <img className="zoom" src={redEmerald} alt="" />
              </a>
            </div>
            <div className="separator1-item-2">
              <a href={"https://www.teilor.ro/index.php?Fl3svu4FwF=1&page=search&action=products&query=smarald"}>
                <img className="zoom" src={turcoazEmerald} alt="" />
              </a>
            </div>
            
          </div>
          
        </div>
        
      </section>
      
    </div>
    
  );
}
