
import React, {useEffect, useRef } from "react";
import "./styles.css";
import { gsap } from "gsap";
import videoDropthemic from "./images/antonia/dropthemic2.mp4";
import Emerald from "./images/birthstones/smaralde.mp4";
import logo from "./images/teilor-logo.svg";

export default function landing() {
  let introHead= useRef(null)
  let introVideo= useRef(null)
  let introDescriere= useRef(null)
  let introButton= useRef(null)
  useEffect(() => {
    gsap.from(introHead, {
      y: "-50px",
      opacity: 0,
      //stagger: 0.2,
      duration: 1.8,
      scale: 1,
      ease: "power4.inOut",
     delay:0.1
    });
    gsap.from(introVideo, {
      x: "50%",
      opacity: 0,
      //stagger: 0.2,
      duration: 2.5,
      scale: 1,
      ease: "power4.inOut",
     delay:0.5
    });

    gsap.from(introDescriere, {
      y: "-50px",
      opacity: 0,
      //stagger: 0.2,
      duration: 1.8,
      scale: 1,
      ease: "power4.inOut",
     delay:0.2
    });
    gsap.from(introButton, {
      y: "-50px",
      opacity: 0,
      //stagger: 0.2,
      duration: 1.8,
      scale: 1,
      ease: "power4.inOut",
     delay:0.3
    });
 
    
  }, [])

  return (
 

            <section className="section1">
              <div className="header">
                 
                 
              <a href={'https://www.teilor.ro'}> <img className="logo" src={logo} /> </a>
                 </div>

      <div className="equal-col col1"> 
      


  <div className="col-7 landing-col">	
  <h2 class="title-page-top" ref={(el) => (introHead = el)}>Emerald Jewellery</h2>	 
    
      <p ref={(el) => (introDescriere = el)}> <span className="description-emerald-bold">Dragoste. Echilibru. Speranţă.</span><br/> O culoare a eleganței și a frumuseții, în care se reflectă armonia. <br/>Mai este luna smaraldului, o piatră prețioasă verde, vie, ce radiază bucurie.</p> 

      <div ref={(el) => (introButton = el)} class="cta-colectie"> <a href={"https://www.teilor.ro/index.php?Fl3svu4FwF=1&page=search&action=products&query=smarald"}> <div className="button1"> Descoperă Colecția </div></a> </div>
</div>

   
<div className="col-6 landing-col">

<video ref={(el) => (introVideo = el)} className='videoDropthemic' controls playsInline autoPlay loop muted>
    <source src={Emerald} type='video/mp4' />
    </video>
  </div>	
</div>
		  





</section> 

    
  );
}
