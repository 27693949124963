import "./styles.css";
import oneLovestory from "./images/lovestory/1.png";
import twoLovestory from "./images/lovestory/story2.png";
import threeLovestory from "./images/lovestory/3.png";
import fourLovestory from "./images/lovestory/2.png";
import fiveLovestory from "./images/lovestory/4.png";
import perspectiveMobile from "./images/lovestory/story-mobil1.png";
import videoLovestory from "./images/lovestory/lovestory.mp4";

export default function Lovestory() {
  return (
    <div className="lovestory-wrapper">
      <section className="section1">
        <div className="love-story">
          <div>
            <div className="desktop-setup">
              <a
                href={
                  "https://www.teilor.ro/inele-de-logodna/love-stories.html"
                }
              >
                <img
                  className="grid-item grid-item-1 zoom"
                  src={oneLovestory}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div>
            <div className="desktop-setup">
              <a
                href={
                  "https://www.teilor.ro/inele-de-logodna/love-stories.html"
                }
              >
                <img
                  className="grid-item grid-item-2 zoom"
                  src={threeLovestory}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div>
            <h2> Love stories </h2>
            <p>
              Suntem diferiți, dar lucrurile care ne aduc aproape sunt aceleași:
              modul în care ne vorbim fără cuvinte. Felul în care cântăm, fără
              note. Și emoțiile pe care le simțim, chiar și fără a fi în același
              loc. <br />
              Love Stories by TEILOR aduce aproape momentele memorabile printr-o
              colecție unică de inele de logodnă.
            </p>
            <div class="cta-colectie">
            <a
                href={
                  "https://www.teilor.ro/inele-de-logodna/love-stories.html"
                }
              >
               <div className="button1"> Descoperă Colecția </div> 
               </a>
               </div>
            <div className="grid-item grid-item-3 desktop-setup">
              {" "}
              <img src={twoLovestory} alt="" />{" "}
            </div>
            <div className="grid-item grid-item-3 space-15 mobile-setup">
              {" "}
              <a
                href={
                  "https://www.teilor.ro/inele-de-logodna/love-stories.html"
                }
              >
                <img src={perspectiveMobile} alt="" />
              </a>{" "}
            </div>
          </div>
          <div>
            {" "}
            <video
              className="videoAntonia"
              playsInline
              autoPlay
              loop
              muted
              controls
            >
              <source src={videoLovestory} type="video/mp4" />
            </video>
          </div>
          <div>
            <div className="desktop-setup">
              <a
                href={
                  "https://www.teilor.ro/inele-de-logodna/love-stories.html"
                }
              >
                <img
                  className="grid-item grid-item-5 zoom"
                  src={fourLovestory}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div>
            <a
              href={"https://www.teilor.ro/inele-de-logodna/love-stories.html"}
            >
              <img
                className="grid-item grid-item-6 zoom"
                src={fiveLovestory}
                alt=""
              />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
